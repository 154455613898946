
import moment from 'moment'

import { defineComponent, defineAsyncComponent } from 'vue'
import { Order } from '@/shared/interfaces/order.interface'
import { DriverActions } from '@/store/modules/drivers/actions'
import { OrderActions } from '@/store/modules/orders/actions'
import { OrderMutations } from '@/store/modules/orders/mutations'
import { VehicleTypeInterface } from '@/shared/interfaces/vehicle.interface'
import { useToast } from 'vue-toastification'
import { PredefinedFeedbackTypes } from '@/shared/interfaces/predefined-reasons.interface'

export default defineComponent({
  name: 'OrderModal',
  components: {
    OrderManualAssignmentToggle: defineAsyncComponent(() => import('@/views/account/orders/components/OrderManualAssignmentToggle.vue')),
    ConfirmationModal: defineAsyncComponent(() => import('@/shared/components/modals/ConfirmationModal.vue')),
    BaseModal: defineAsyncComponent(() => import('@/shared/components/modals/BaseModal.vue')),
    OrdersMap: defineAsyncComponent(() => import('@/views/account/orders/components/OrdersMap.vue')),
    OrderManuelAssignModal: defineAsyncComponent(() => import('@/views/account/orders/components/OrderManuelAssignModal.vue')),
    OrderManuelUnAssignModal: defineAsyncComponent(() => import('@/views/account/orders/components/OrderManuelUnAssignModal.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect')),
    ReasonForm: defineAsyncComponent(() => import('@/views/account/orders/components/ReasonForm.vue'))
  },
  data () {
    return {
      moment,
      searchValue: '',
      interval: null as any,
      status: null as any,
      showUpdateStatusModal: false,
      showUpdateVehicleTypeModal: false,
      vehicleType: null as any,
      showAssignDriverModal: false,
      showUnassign: false,
      showUpdateStatusConfirmation: false,
      openedIndex: 0,
      statusesNeedToChecked: ['assigned', 'picked_up', 'on_the_way', 'at_the_address', 'to_return', 'to_return_assigned'],
      relatedOrderExpand: true,
      showRequestDeliveryConfirmation: false,
      showLastOrderConfirmation: false,
      awaitFurtherOrders: false,
      showManualAssignmentToggleConfirmation: false,
      isManual: false,
      orderDriver: [] as any,
      unAssignReason: null as any,
      isManualReason: null as any,
      PredefinedFeedbackTypes
    }
  },
  props: {
    orderData: {
      type: Object as () => Order,
      required: true
    }
  },
  computed: {
    isSuperAdmin (): boolean {
      return this.$store.getters.isSuperAdmin
    },
    distance (): number {
      let outwardDistance = 0
      let inwardDuration = 0
      if (this.orderData.tripDetailsFromDarkstoreOutward) {
        this.orderData.tripDetailsFromDarkstoreOutward.forEach(el => {
          outwardDistance += el.travelDistance
        })
      }
      if (this.orderData.tripDetailsToDarkstoreInward) {
        inwardDuration = this.orderData.tripDetailsToDarkstoreInward.travelDistance
      }
      return (outwardDistance + inwardDuration) / 1000
    },
    direction () {
      return {
        from: this.orderData.driver ? this.orderData.driver.lastLocation : null,
        to: { latitude: this.orderData.destinationLatitude, longitude: this.orderData.destinationLongitude }
      }
    },
    tripDetails () {
      if (this.orderData.tripDetailsToDarkstoreInward && this.orderData.tripDetailsFromDarkstoreOutward) {
        return {
          toDarkstore: this.orderData.tripDetailsToDarkstoreInward,
          fromDarkstore: this.orderData.tripDetailsFromDarkstoreOutward,
          status: this.orderData.orderStatus.name,
          driverLocation: this.orderData.driverLastLocation ? this.orderData.driverLastLocation : null,
          orderData: this.orderData
        }
      } else {
        return {
          toDarkstore: null,
          fromDarkstore: null,
          status: this.orderData.orderStatus.name,
          driverLocation: null,
          orderData: this.orderData
        }
      }
    },
    statuses (): any {
      return this.$store.getters['orders/getOrderStatuses']
    },
    vehicleTypes (): VehicleTypeInterface[] {
      return this.$store.getters['vehicles/getVehicleTypes']
    },
    toStoreDuration (): string {
      if (this.orderData.tripDetailsToDarkstoreInward) {
        const minutes = Math.ceil(this.orderData.tripDetailsToDarkstoreInward.travelDuration / 60)
        return minutes === 1 ? `${minutes} ${this.$t('orders.minuteToStore')}` : `${minutes} ${this.$t('orders.minutesToStore')}`
      } else {
        return ''
      }
    },
    relatedOrders () {
      return this.orderData?.linkedOrders ?? []
    },
    unassignTitle (): string {
      const driver = `${this.orderData.driver?.driverInfo.firstName} ${this.orderData.driver?.driverInfo.lastName}`
      return this.$t('orders.unassign.title', { driver, darkstore: this.orderData.darkStore.name, orderNumber: this.orderData.orderId })
    },
    showManualAssignmentItems () {
      return ['preparing', 'ready_for_pick_up', 'assigned', 'picked_up'].includes(this.orderData.orderStatus.name)
    },
    manualAssignmentConfirmMessage () {
      return this.hasManualAssignment ? this.$t('orders.manualAssignmentToggle.messageToAuto')
        : this.$t('orders.manualAssignmentToggle.messageToManuel')
    },
    hasManualAssignment () {
      return this.orderData.isManual ? this.orderData.isManual : false
    }
  },
  mounted () {
    // this.getDriversList()
    // this.getNearbyOrders()
    this.interval = setInterval(() => {
      // this.$emit('resetDriverData', { orderId: this.orderData.id })
      if (this.orderData?.driver?.id && this.orderData?.driver?.driverInfo?.id && this.statusesNeedToChecked.includes(this.orderData.orderStatus.name)) {
        this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVER_CURRENT_LOCATION}`, { driverId: this.orderData.driver.id, orderId: this.orderData.id })
          .then((resp: any) => {
            this.$store.commit(`orders/${OrderMutations.SET_ORDER_DRIVER_LOCATION}`, resp)
            if (this.orderDriver.length && this.orderDriver[0].id === resp.id) {
              const driver = {
                ...this.orderDriver[0],
                latitude: Number(resp.latitude),
                longitude: Number(resp.longitude)
              }
              this.orderDriver = [driver]
            }
          })
      }
    }, 10000)
    this.isManual = this.hasManualAssignment
  },
  setup (_, { emit }) {
    const closeModal = ($event: any, out = true) => {
      if (out && !$event.inner) {
        emit('close', false)
      } else $event.inner = true
    }
    return {
      closeModal
    }
  },
  methods: {
    clickPanel (index: any): void {
      this.openedIndex = this.openedIndex !== index ? index : null
    },
    getDriversList (): void {
      const params = { orderId: this.orderData.id }
      this.$store.dispatch(`drivers/${DriverActions.FETCH_AVAILABLE_DRIVERS}`, params)
    },
    getNearbyOrders (): void {
      if (!this.orderData.driver && (this.orderData.orderStatus.name === 'preparing' || this.orderData.orderStatus.name === 'ready_for_pick_up')) {
        this.$store.dispatch(`orders/${OrderActions.FETCH_NEARBY_ORDERS}`, this.orderData.id)
      }
    },

    cancelOrder () {
      this.$store.dispatch(`orders/${[OrderActions.CANCEL_ORDER]}`, { orderId: this.orderData.id }).then((res: any) => {
        if (res?.data?.info?.message === 'success') {
          const toast = useToast()
          toast.success('Order cancelled succesfully', {
            timeout: 15000
          })
          this.$emit('close', { reset: true })
        }
      })
    },
    updateOrderStatus (): void {
      this.$store.dispatch(`orders/${OrderActions.UPDATE_STATUS}`, { orderId: this.orderData.id, statusId: this.status.id })
        .then((response:any) => {
          this.$store.commit(`orders/${OrderMutations.UPDATE_ORDER_STATUS}`, {
            orderId: this.orderData.id, status: this.status, orderStatusTime: response.data.data.order_status_time
          })
          this.showUpdateStatusModal = false
        })
    },
    updateVehicleType (): void {
      this.$store.dispatch(`orders/${OrderActions.UPDATE_VEHICLE_TYPE}`, { orderId: this.orderData.id, vehicleTypeId: this.vehicleType.id }).then(() => {
        this.$store.commit(`orders/${OrderMutations.UPDATE_ORDER_VEHICLE_TYPE}`, this.vehicleType)
        this.showUpdateVehicleTypeModal = false
      })
    },
    redirectToWhatsapp (phoneNumber: string): void {
      let numberArry = phoneNumber.split('').reverse()
      numberArry.pop()
      numberArry = numberArry.reverse()
      const number = numberArry.join('').split(' ').join('')
      window.open('https://wa.me/+964' + number)
    },
    redirectToGoogleMap (): void {
      const statuses = ['picked_up', 'on_the_way', 'at_the_address', 'to_return', 'to_return_assigned']
      const toDarkstore = statuses.includes(this.orderData.orderStatus.name)
      let origin = ''
      let destination = ''
      if (this.orderData.tripDetailsToDarkstoreInward) {
        if (toDarkstore) {
          destination = `${this.orderData.tripDetailsToDarkstoreInward.endLocation.lat},${this.orderData.tripDetailsToDarkstoreInward.endLocation.lng}`
        } else {
          destination = `${this.orderData.destinationLatitude},${this.orderData.destinationLongitude}`
        }
        if (this.orderData.driver) {
          origin = `${this.orderData.driver.lastLocation?.latitude},${this.orderData.driver.lastLocation?.longitude}`
        } else {
          origin = `${this.orderData.tripDetailsToDarkstoreInward.endLocation.lat},${this.orderData.tripDetailsToDarkstoreInward.endLocation.lng}`
        }
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`)
      }
    },
    getOrderStatusTime () {
      const status = this.orderData.orderStatus.name as string
      if (Array.isArray(this.orderData.orderStatusTime)) {
        const statusObject = this.orderData.orderStatusTime.filter((s) => s.status === status)
        if (statusObject.length && statusObject[0].value !== null) {
          return statusObject[0].value
        }
      }
      return this.orderData.createdAt
    },
    getOrderLatestStatusChangeTime () {
      let time = ''
      const now = moment()
      const statusTime = this.getOrderStatusTime()
      const duration = moment.duration(now.diff(statusTime)) as any
      const days = parseInt(duration.asDays())
      // duration in hours
      const hours = parseInt(duration.asHours()) % 24
      // duration in minutes
      const minutes = parseInt(duration.asMinutes()) % 60
      if (days > 0) {
        time += days + 'd '
      }
      if (hours > 0) {
        time += hours + 'h '
      }
      if (minutes > 0) {
        time += minutes + 'min'
      }
      time += ' ago'
      if (!days && !hours && !minutes) {
        time = 'now'
      }
      return time
    },
    async requestDelivery () {
      if (this.orderData.driver) {
        await this.$store.dispatch(`drivers/${[DriverActions.FORCE_TO_START_DELIVERY]}`, this.orderData.driver.id)
        this.showRequestDeliveryConfirmation = false
      }
    },
    unAssignClickHandler () {
      if (this.orderData.linkedOrders && this.orderData.linkedOrders.length) {
        this.showUnassign = true
      } else {
        this.showLastOrderConfirmation = true
      }
    },
    unAssignLastOrder () {
      const orderId = this.orderData.id
      const payload = {
        await_further_orders: this.awaitFurtherOrders ? 1 : 0,
        is_manual: this.isManual ? 1 : 0,
        ...this.unAssignReason
      }
      this.$store.dispatch(`orders/${[OrderActions.MANUEL_ORDER_UN_ASSIGN]}`, { orderId, payload }).then(() => {
        this.$emit('resetDriverData', { orderId: this.orderData.id })
      }).finally(() => {
        this.showLastOrderConfirmation = false
      })
    },
    async updateOrderManualAssignmentStatus () {
      this.showManualAssignmentToggleConfirmation = false
      await this.$store.dispatch(`orders/${OrderActions.TOGGLE_ORDER_MANUAL}`, {
        orderId: this.orderData.id,
        reasonObject: this.isManualReason
      })
      this.$emit('resetDriverData', { orderId: this.orderData.id })
    },
    async getOrderDriver () {
      if (this.orderData.driver) {
        const response = await this.$store.dispatch(`drivers/${[DriverActions.FETCH_SINGLE_DRIVER]}`, this.orderData.driver.id)
        this.orderDriver = [{
          ...response,
          driver: {
            driverInfo: response.driverInfo,
            vehicleInfo: {
              licenseNo: response.driverLicenseNo,
              model: response.vehicleId.model,
              make: response.vehicleId.model
            }
          },
          latitude: response?.lastLocation?.latitude ?? 0,
          longitude: response?.lastLocation?.longitude ?? 0
        }]
      }
    }
  },
  watch: {
    orderData: {
      immediate: true,
      handler: function (val: Order) {
        if (val && val.orderStatus) {
          this.status = val.orderStatus
          this.vehicleType = val.vehicleType
          if (val.linkedOrders && val.linkedOrders.length) {
            const linkedOrderIds = val.linkedOrders.map((order:any) => (order.order_id))
            this.$emit('linkedOrderIds', linkedOrderIds)
          } else {
            this.$emit('linkedOrderIds', [])
          }
        }
        this.getOrderDriver()
      }
    },
    hasManualAssignment (newVal) {
      this.isManual = newVal
    }
  },
  unmounted () {
    clearInterval(this.interval)
  }
})
